<template>
    <div class="container-fluid d-flex flex-column ">


        <div class="row justify-content-center fill-width">
            <div class="col-md-6 col-lg-5 col-xl-4 py-6 py-md-0 mt-5">
                <div class="card" style="max-width: 600px; margin: 0 auto;">
                    <div class="card-body">

                        <div>
                            <div class="mb-5 mt-5 text-center">
                                <h3 class="h3-green" style="font-size:40px;">Inloggen</h3>
                                <p><strong>Vul hieronder je gegevens in om te in loggen.</strong></p>
                            </div>

                            <span class="clearfix"></span>

                            <ValidationObserver ref="login" v-slot="{ invalid }" tag="form">

                                <form>

                                    <div class="form-group mb-3">
                                        <label class="form-control-label">Emailadres: <sup>*</sup></label>
                                        <div class="">
                                            <validation-provider rules="required" v-slot="{ errors }">
                                                <input :disabled="loading" type="email" v-model="request.email" class="form-control fusion-form-input" id="email" name="email" placeholder="" @keydown.enter="login">
                                                <small class="error">{{ errors[0] }}</small>
                                            </validation-provider>
                                        </div>
                                    </div>

                                    <div class="form-group mb-3">
                                        <label class="form-control-label">Wachtwoord: <sup>*</sup></label>
                                        <div class="">
                                            <validation-provider rules="required" v-slot="{ errors }">
                                                <input :disabled="loading" type="password" v-model="request.password" class="form-control" placeholder="" @keydown.enter="login" name="password">
                                                <small class="error">{{ errors[0] }}</small>
                                            </validation-provider>
                                        </div>
                                    </div>

                                    <div class="mt-1">
                                        <button class="btn btn-primary btn-caps" type="button" @click="login" style="" :disabled="invalid || loading" id="login-button">
                                            Inloggen
                                        </button>
                                    </div>

                                    <div class="text-center form-group mt-3">
                                        <small>
                                            <router-link :to="`/wachtwoord-vergeten`" id="login-password-reset-link">Wachtwoord vergeten</router-link>
                                        </small> |
                                        <small>
                                            <router-link :to="`/register`" id="login-register-link">Registreer</router-link>
                                        </small>
                                    </div>


                                    <div class="alert alert-danger mt-5" v-if="errormessage">{{ errormessage }}</div>
                                </form>

                            </ValidationObserver>

                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>
</template>

<script>

    import {extend, ValidationObserver, ValidationProvider} from 'vee-validate';
    import {required} from 'vee-validate/dist/rules';

    extend('required', {
        ...required,
        message: 'Dit veld is verplicht'
    });

    export default {

        components: {
            ValidationProvider,
            ValidationObserver,
        },

        data() {
            return {
                request: {
                    email: null,
                    password: null,
                },
                errormessage: null,
                loading: false
            };
        },

        methods: {
            async login($event) {
                this.loading = true;

                if ($event) {
                    $event.preventDefault();
                }

                this.errormessage = null;

                try {
                    const email = this.request.email;
                    const password = this.request.password;

                    const response = await this.$http.post(this.$root.api_url + '/api/auth/login', {
                        email,
                        password
                    });

                    await this.$store.dispatch('login', {
                        user: response.data.user,
                        token: response.data.token
                    });

                    await this.$router.push('/');
                } catch (exception) {
                    this.errormessage = "Het inloggen is helaas niet gelukt, Controleer je gegevens en probeer het opnieuw";
                }

                this.loading = false;
            }
        }
    }

</script>

<style>

</style>
