<template>
    <div class="summary-box p-3 h-100">
        <div>
            <div class="row">
                <div class="col-12">
                    <div><h2 class="title-heading-left mb-3" style="">Algemeen </h2></div>
                    <ul class="menu-ul">
                        <li>
                            <font-awesome-icon class="menu-icon" icon="paper-plane"></font-awesome-icon>
                            <router-link to="/nieuwe-ondertekening" :class="checkActiveRoute('/nieuwe-ondertekening')" id="nieuwe-ondertekening-link-side-menu"> Nieuwe ondertekening</router-link>
                        </li>
                        <li>
                            <font-awesome-icon class="menu-icon" icon="copy"></font-awesome-icon>
                            <router-link to="/" :class="checkActiveRoute('/')"> Ondertekeningen</router-link>
                        </li>
                        <li v-if="hasFeature(company, 'templates')">
                            <font-awesome-icon class="menu-icon" icon="paste"></font-awesome-icon>
                            <router-link :to="{name: 'Templates'}" :class="checkActiveRoute('Templates')"> Templates</router-link>
                        </li>
                        <li>
                            <font-awesome-icon class="menu-icon" icon="user-circle"></font-awesome-icon>
                            <router-link :to="{name: 'AccountSettings'}" :class="checkActiveRoute('AccountSettings')"> Account instellingen</router-link>
                        </li>
                        <li v-if="hasFeature(company, 'email_templates')">
                            <font-awesome-icon class="menu-icon" icon="mail-bulk"></font-awesome-icon>
                            <router-link :to="{name: 'EmailTemplates'}" :class="checkActiveRoute('EmailTemplates')"> Email templates</router-link>
                        </li>
                        <li v-if="hasFeature(company, 'api')">
                            <font-awesome-icon class="menu-icon" icon="key"></font-awesome-icon>
                            <router-link :to="{name: 'Api'}" :class="checkActiveRoute('Api')"> API sleutels</router-link>
                        </li>
                        <li v-if="hasFeature(company, 'webhooks')">
                            <font-awesome-icon class="menu-icon" icon="reply-all"></font-awesome-icon>
                            <router-link :to="{name: 'Webhooks'}" :class="checkActiveRoute('Webhooks')"> Webhook berichten</router-link>
                        </li>
                        <li>
                            <font-awesome-icon class="menu-icon" icon="mail-bulk"></font-awesome-icon>
                            <router-link :to="{name: 'Users'}" :class="checkActiveRoute('Users')"> Gebruikers</router-link>
                        </li>
                        <li>
                            <font-awesome-icon class="menu-icon" icon="credit-card"></font-awesome-icon>
                            <router-link :to="{name: 'Credits'}" :class="checkActiveRoute('Credits')"> Credits & Abonnementen</router-link>
                        </li>

                        <li>
                            <font-awesome-icon class="menu-icon" icon="file-invoice"></font-awesome-icon>
                            <router-link :to="{name: 'Invoices'}" :class="checkActiveRoute('Invoices')"> Facturen</router-link>
                        </li>

                        <li v-if="user.admin">
                            <hr />
                        </li>

						<li v-if="user.admin">
							<font-awesome-icon class="menu-icon" icon="users"></font-awesome-icon>
							<router-link :to="{name: 'AllUsers'}" :class="checkActiveRoute('AllUsers')"> Alle gebruikers (admin) </router-link>
						</li>

                        <li v-if="user.admin">
                            <font-awesome-icon class="menu-icon" icon="file-invoice"></font-awesome-icon>
                            <router-link :to="{name: 'AllInvoices'}" :class="checkActiveRoute('AllInvoices')"> Alle facturen (admin) </router-link>
                        </li>

                        <li v-if="user.admin">
                            <font-awesome-icon class="menu-icon" icon="credit-card"></font-awesome-icon>
                            <router-link :to="{name: 'AllPaymentPlans'}" :class="checkActiveRoute('AllPaymentPlans')"> Alle abonnementen (admin) </router-link>
                        </li>

                        <li v-if="user.admin" @click="consent()">
                            <font-awesome-icon class="menu-icon" icon="key"></font-awesome-icon>
                            <a href="#"> Office365 consent </a>
                        </li>

                        <li>
                            <hr />
                        </li>

                        <li>
                            <font-awesome-icon class="menu-icon" icon="question-circle"></font-awesome-icon>
                            <a :href="faqLink" target="_blank"> Veelgestelde vragen</a>
                        </li>

                        <li>
                            <font-awesome-icon class="menu-icon" icon="headset"></font-awesome-icon>
                            <router-link :to="{name: 'Contact'}" :class="checkActiveRoute('Contact')"> Contact & support</router-link>
                        </li>

                        <li>
                            <font-awesome-icon class="menu-icon" icon="file-contract"></font-awesome-icon>
                            <a :href="termsLink" target="_blank"> Algemene voorwaarden</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex';

    export default {

        computed: mapState({
            company: state => state.company,
            user: state => state.user,
            termsLink: state => state.termsLink,
            faqLink: state => state.faqLink,
        }),

        methods: {
            checkActiveRoute(routename) {
                if (this.$route.name === routename) {
                    return 'active';
                }

                if (this.$route.path === routename) {
                    return 'active';
                }
            },

            async setOAuthState() {
                try {
                    const response = await this.$http.post('/api/set-oauth-state');

                    console.log(response.data);

                } catch (exception) {
                    this.errorMessage = exception?.response?.data?.message || exception?.message || 'Something went wrong';
                }
            },

            async consent() {


                try {
                    await this.setOAuthState();

                    const response = await this.$http.post('/api/get-admin-consent-url');

                    window.location = response.data.url;

                } catch (exception) {
                    this.errorMessage = exception?.response?.data?.message || exception?.message || 'Something went wrong';
                }


            },
        }
    }
</script>

<style scoped>

</style>
